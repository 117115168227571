import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormControl } from "react-bootstrap";

import calendarPDF from "../../legalDocuments/2023_CAIPA_Calendar_updated.pdf";
import SearchContainer from "../../components/SearchContainer";
import CustomButton from "../../components/common/CustomButton";
import ModalProvider from "../../components/common/ModalProvider";
import { contactSupportText } from "../../components/common/utils/constants";

import classes from "./DashboardContainer.module.css";
import { IoDocumentText } from "react-icons/io5";
import { FaPeopleGroup } from "react-icons/fa6";
import { PiTreeStructureFill } from "react-icons/pi";
import { TbPresentation } from "react-icons/tb";
import { FaPollH } from "react-icons/fa";
import { MdMenuBook } from "react-icons/md";

const linkOrder = [
  "Meeting Minutes",
  "Organizational Structure",
  "Training",
  "Documents",
  "Presentations",
];

const linkIcons = {
  ["Meeting Minutes"]: FaPeopleGroup,
  ["Organizational Structure"]: PiTreeStructureFill,
  ["Training"]: MdMenuBook,
  ["Documents"]: IoDocumentText,
  ["Presentations"]: TbPresentation,
};

function DashboardLinks({
  linksInfo,
  currentUserId,
  setBrandName,
  searchArray,
  keyword,
  setKeyword,
}) {
  const navigate = useNavigate();

  const isLoading = linksInfo.length === 0;

  const [linksInfoMap, setLinksInfoMap] = useState(null);
  const [openCalendar, setOpenCalendar] = useState(false);

  function handleOpenCalendar() {
    setOpenCalendar(true);
  }
  function handleCloseCalendar() {
    setOpenCalendar(false);
  }

  function handleNavigate(link) {
    const { path, id, folder_name } = link;
    setBrandName(folder_name);
    setKeyword("");

    navigate(`/${folder_name}`, {
      state: {
        parentPath: path,
        currentFolderId: id,
        currentName: folder_name,
      },
    });
  }

  useEffect(() => {
    const map = {};
    for (const link of linksInfo) {
      map[link.folder_name] = link;
    }
    setLinksInfoMap(map);
  }, [linksInfo]);

  if (isLoading || !linksInfoMap) {
    return <></>;
  }

  return (
    <>
      <div className={classes.right_container} id="custom-scroll-y-thin">
        <div className={classes.right_container_grid}>
          <div className={classes.search_input_wrapper}>
            <div className={classes.search_input_inner_wrapper}>
              <FormControl
                type="search"
                placeholder="Search"
                onChange={(e) => setKeyword(e.target.value)}
                className={classes.search_box}
              />
              {keyword && (
                // (1) //
                <SearchContainer
                  currentUserId={currentUserId}
                  setBrandName={setBrandName}
                  searchArray={searchArray}
                  keyword={keyword}
                  setKeyword={setKeyword}
                />
              )}
            </div>
          </div>

          {/* No calendar for the CARE, MSO, Foundation */}
          {/* <div className={classes.calendar_button_wrapper}>
            <CustomButton
              onClick={handleOpenCalendar}
              className={classes.calendar_button}
            >
              2023 Calendar
            </CustomButton>
          </div> */}

          {linkOrder.map((linkName, index) => {
            const link = linksInfoMap[linkName];
            const Icon = linkIcons[linkName];
            const rotateIcon = linkName === "Organizational Structure";

            return (
              <div className={classes.link_grid_box} key={index}>
                <div className={classes.link_wrapper}>
                  <div
                    className={classes.link}
                    onClick={() => handleNavigate(link)}
                  >
                    <div className={classes.link_icon}>
                      <Icon className={rotateIcon ? classes.rotate_icon : ""} />
                    </div>
                    <div className={classes.link_text}>{link.folder_name}</div>
                  </div>
                </div>
              </div>
            );
          })}

          <div className={classes.link_grid_box}>
            <div className={classes.link_wrapper}>
              <div
                className={classes.link}
                onClick={() =>
                  handleNavigate({ path: "", id: 0, folder_name: "Poll" })
                }
              >
                <div className={classes.link_icon}>
                  <FaPollH />
                </div>
                <div className={classes.link_text}>Poll</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ModalProvider
        show={openCalendar}
        handleClose={handleCloseCalendar}
        closeOnBackdropClick={true}
        size="lg"
      >
        <div className="_g-pdf-view-wrapper_">
          <div className="_g-poll-modal-title_" style={{ textAlign: "center" }}>
            2023 CAIPA Calendar
          </div>
          <object
            type="application/pdf"
            data={calendarPDF}
            className="_g-pdf-view_"
          >
            <div className="_g-pdf-view-failed_">
              Failed to load the file, {contactSupportText}
            </div>
          </object>
        </div>
      </ModalProvider>
    </>
  );
}

export default memo(DashboardLinks);

/*
  (1) The "search" is a fukking FAKE search, all the "files" and "folders" 
      are fetched from backend when this app is loaded. the "search" is just 
      a fukking "filtering".
      
      The dropdown in the <SearchContainer/> does not has a max height, it will
      overflow all over the viewport. I need to add 
          overflow: "auto",
          maxHeight: "calc(100vh - 400px)",
          left: 0,
          marginTop: "4px",
          minWidth: "min(480px,70vw)",
          maxWidth: "600px",

      in order to display this shitty dropdown properly
*/
