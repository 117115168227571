import { memo, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { useCurrentUser } from "../../services/auth";
import { useGetEventList } from "./hooks/useGetEventList";
import DashboardEventList from "./DashboardEventList";
import DashboardUpload from "./DashboardUpload";
import CustomButton from "../../components/common/CustomButton";
import DashboardLinks from "./DashboardLinks";
import { useGetContentList } from "./hooks/useGetContentList";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { API_URL } from "../../services/api";
import { getLocalTime } from "./helpers/get-local-time";
import { getGreetingText } from "./helpers/get-greeting-text";

import classes from "./DashboardContainer.module.css";
import { TbHeartRateMonitor } from "react-icons/tb";
import logo from "../../imgs/CAIPA-PORTAL-LOGO.png";
import default_avatar from "../../imgs/default_avatar.png";

function DashboardContainer({
  currentUserId,
  firstName,
  lastName,
  isAdmin,
  setBrandName,
  searchArray,
  keyword,
  setKeyword,
  logoutMutation,
}) {
  const { data: currentUser } = useCurrentUser();
  const { eventList, getEventList } = useGetEventList();
  const { contentList } = useGetContentList();

  const isSmallScreen = useMediaQuery({ query: "(max-width: 1000px)" });

  const [openNewTask, setOpenNewTask] = useState(false);

  function handleOpenNewTask() {
    setOpenNewTask(true);
  }

  async function handleLogout() {
    await logoutMutation();
    navigate("/");
  }

  let list = eventList;
  if (currentUser.role !== 1) {
    list = eventList.filter((task) => task.draft === 0);
  }
  const isLoading = !eventList || !contentList;

  const avatar =
    currentUser.avatar === null ? default_avatar : `${API_URL}/getAvatar`;

  return (
    <>
      <main className={classes.container}>
        <div className={classes.banner_container}>
          <div className={classes.banner_inner_container}>
            <div className={classes.banner_flex_box}>
              <div className={classes.logo_user_wrapper}>
                <div className={classes.banner_logo_wrapper}>
                  <img src={logo} className={classes.banner_logo} />
                </div>
                <div className={classes.user_name}>
                  {getGreetingText()}, {isAdmin ? firstName : `Dr. ${lastName}`}
                </div>
                {currentUser.last_logout && (
                  <div className={classes.last_seen}>
                    Last logout {getLocalTime(currentUser.last_logout)}
                  </div>
                )}
              </div>
            </div>
            <div className={classes.avatar_flex_box}>
              <div className={classes.avatar_wrapper}>
                <img src={avatar} alt="avatar" className={classes.avatar} />
              </div>
              <CustomButton
                className={classes.logout_button}
                variant="danger"
                onClick={handleLogout}
              >
                Logout
              </CustomButton>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className={classes.body_loading}>
            <LoadingSpinner size="lg" />
          </div>
        ) : (
          <div className={classes.body_container}>
            {isSmallScreen && contentList && (
              <DashboardLinks
                linksInfo={contentList}
                currentUserId={currentUserId}
                setBrandName={setBrandName}
                searchArray={searchArray}
                keyword={keyword}
                setKeyword={setKeyword}
              />
            )}

            <div className={classes.task_container}>
              <div className={classes.task_title_wrapper}>
                <div className={classes.task_title}>
                  <TbHeartRateMonitor className={classes.task_icon} />
                  Task{" "}
                  <span className={classes.task_count}>
                    - {list.length} available
                  </span>
                </div>
                {currentUser.role === 1 && (
                  <CustomButton
                    variant="outline-success"
                    onClick={handleOpenNewTask}
                    className={classes.new_task_button}
                  >
                    New Task
                  </CustomButton>
                )}
              </div>
              <div className={classes.task_list} id="custom-scroll-y-thin">
                {list.length > 0 ? (
                  list.map((task, index) => (
                    <div className={classes.task_wrapper} key={index}>
                      <DashboardEventList
                        key={task.id + task.event_name}
                        eventId={task.id}
                        eventName={task.event_name}
                        eventDate={task.event_date}
                        startDate={task.start_date}
                        description={task.description}
                        getEvents={getEventList}
                        draft={task.draft}
                      />
                    </div>
                  ))
                ) : (
                  <div className={classes.no_task}>
                    No task is available at this time.
                  </div>
                )}
              </div>
            </div>

            {!isSmallScreen && contentList && (
              <DashboardLinks
                linksInfo={contentList}
                currentUserId={currentUserId}
                setBrandName={setBrandName}
                searchArray={searchArray}
                keyword={keyword}
                setKeyword={setKeyword}
              />
            )}
          </div>
        )}
      </main>

      <DashboardUpload
        isOpen={openNewTask}
        setIsOpen={setOpenNewTask}
        currentUserId={currentUserId}
        getEvents={getEventList}
      />
    </>
  );
}

export default memo(DashboardContainer);
