/* eslint-disable eqeqeq */
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { QueryClientProvider } from "react-query";
import { Routes, Route, Navigate, useLocation } from "react-router";
import { API_URL } from "./services/api";
import default_avatar from "./imgs/default_avatar.png";
import { React, useEffect } from "react";
import BoardMemberPortalContainer from "./components/BoardMemberPortalContainer";
import Login from "./pages/Login";
import { FoldersProvider } from "./services/contexts/useFolders";
// import LoginPage from './components/LoginPage';

import queryClient from "./services/queryClient";
import { useCurrentUser } from "./services/auth";
import ResetPasswordPage from "./pages/Login/ResetPasswordPage";
import LoadingSpinner from "./components/common/LoadingSpinner";
import packageJson from "../package.json";

function DashboardApp() {
  const { data, refetch, isLoading } = useCurrentUser();
  const isLoggin = data?.loggedIn;
  const disclaimer = data?.disclaimer;

  useEffect(() => {
    document.title = `CAIPA Care Board of Director v${packageJson.version}`;
  }, []);

  if (isLoading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingSpinner size="lg" />
      </div>
    );
  }

  return (
    <div className="App">
      <header className="App-header" />
      <Routes>
        {!isLoggin ? (
          <Route path="*" element={<Login refetch={refetch} />} />
        ) : (
          <Route
            path="/*"
            element={
              <BoardMemberPortalContainer
                refetch={refetch}
                currentUser={data.user}
                currentUserId={data.id}
                firstName={data.first_name}
                role={data.role}
                lastName={data.last_name}
                avatar={
                  data.avatar === null ? default_avatar : `${API_URL}/getAvatar`
                }
              />
            }
          />
        )}
        <Route
          exact
          path="*"
          element={<Navigate to={isLoggin ? "/" : "/"} />}
        />
        <Route path="/auth/identity/*" element={<ResetPasswordPage />} />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <FoldersProvider>
        <DashboardApp />
      </FoldersProvider>
    </QueryClientProvider>
  );
}

export default App;
