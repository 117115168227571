/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */

import { React, useState } from "react";
import { Modal } from "react-bootstrap";
import API, { API_URL } from "../../services/api";
import { useCurrentUser } from "../../services/auth";
import AllLastSeen from "../../components/AllLastSeen";
import LastSeen from "../../components/LastSeen";
import { useAllFolders } from "../../services/contexts/useFolders";
import PollAttachmentIcon from "../../components/Poll/PollContainer/PollAttachment/PollAttachmentIcon";
import ModalProvider from "../../components/common/ModalProvider";
import { contactSupportText } from "../../components/common/utils/constants";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { Fragment } from "react";
import CustomButton from "../../components/common/CustomButton";

import classes from "./DashboardFileView.module.css";

function DashboardFileView(props) {
  const { dashboardFileList, eventId, getDashboardFileList, setShowNew } =
    props;
  const { allFolders } = useAllFolders();
  const [isOpen, setIsOpen] = useState(false);
  const { data: currentUser } = useCurrentUser();
  const { role } = currentUser;
  const [fileId, setFileId] = useState(null);
  const [fileTitle, setFileTitle] = useState(null);

  const [openViewer, setOpenViewer] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);

  function handleOpenViewer(currentFile) {
    setCurrentFile(currentFile);
    setOpenViewer(true);
  }
  function handleCloseViewer() {
    setOpenViewer(false);
    setTimeout(() => {
      setCurrentFile(null);
    }, 500);
  }

  const handleOpen = (el, name) => {
    setFileId(el);
    setIsOpen(true);
    setFileTitle(name);
  };

  const deleteDashboardFile = () => {
    API.delete(`/admin/deleteDashboardFile`, { data: { fileId } }).then(
      (response) => {
        getDashboardFileList();
        if (response) {
          setIsOpen(false);
        }
      }
    );
  };
  const dashboardFolder = allFolders.find((obj) => {
    return obj.path === "root" && obj.folder_name === "Dashboard";
  });

  return (
    <div className="linkList">
      {dashboardFileList.map((el, index) =>
        el.dashboard_event_id === eventId ? (
          <Fragment key={index}>
            <div
              key={el.dashboard_event_id + el.document_title}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "5px 0",
                gap: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexFlow: "row wrap",
                  gap: "4px",
                }}
              >
                <div
                  onClick={() =>
                    handleOpenViewer({
                      url: `${API_URL}/getFile?file_name=${el.filename}&file_id=${el.id}`,
                      title: el.document_title,
                    })
                  }
                  style={{ marginRight: "-20px" }}
                >
                  <PollAttachmentIcon
                    originalName={el.document_title}
                    mimetype="application/pdf"
                  />
                </div>
                {role !== 1 && (
                  <div className={classes.last_seen_wrapper}>
                    <LastSeen
                      file={el}
                      setShowNew={setShowNew}
                      userId={currentUser.id}
                      fileId={el.id}
                      showDefault
                    />
                  </div>
                )}
              </div>
              {role === 1 && (
                <div className={classes.file_actions_wrapper}>
                  <AllLastSeen
                    userId={currentUser.id}
                    fileId={el.id}
                    showDefault
                  />
                  <CustomButton
                    onClick={() => handleOpen(el.id, el.document_title)}
                    className={classes.file_delete_button}
                    variant="outline-danger"
                  >
                    Delete
                  </CustomButton>
                </div>
              )}
            </div>
          </Fragment>
        ) : (
          ""
        )
      )}

      <Modal show={isOpen} onHide={() => setIsOpen(false)} backdrop="static">
        <Modal.Header className="_g-modal-header_">
          <div className="_g-close-button-anchor_">
            <div className="_g-close-button_" onClick={() => setIsOpen(false)}>
              &#x2715;
            </div>
          </div>
          Confirm Delete
        </Modal.Header>
        <Modal.Body className="_g-modal-body_">
          <div className="_g-modal-body-text_">
            Are you sure you would like to delete this file?
          </div>
          <div className="_g-modal-button-wrapper_">
            <CustomButton
              variant="outline-secondary"
              onClick={() => setIsOpen(false)}
            >
              Cancel
            </CustomButton>
            <CustomButton
              variant="primary"
              onClick={() => {
                deleteDashboardFile(fileId);
              }}
            >
              Delete
            </CustomButton>
          </div>
        </Modal.Body>
      </Modal>

      <ModalProvider
        show={openViewer}
        handleClose={handleCloseViewer}
        closeOnBackdropClick={true}
        size="lg"
      >
        {currentFile ? (
          <div className="_g-pdf-view-wrapper_">
            <div
              className="_g-poll-modal-title_"
              style={{ textAlign: "center" }}
            >
              {currentFile.title}
            </div>
            <object
              type="application/pdf"
              data={currentFile.url}
              className="_g-pdf-view_"
            >
              <div className="_g-pdf-view-failed_">
                Failed to load the file, {contactSupportText}
              </div>
            </object>
          </div>
        ) : (
          <LoadingSpinner />
        )}
      </ModalProvider>
    </div>
  );
}

export default DashboardFileView;
