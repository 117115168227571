/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
// eslint-disable-next-line no-unused-vars
import { React, useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import API from "../services/api";
import { Colors } from "../constants";
import Confirmation from "./Confirmation";
import folderpng from "../imgs/Folder.png";
import CustomButton from "./common/CustomButton";

import classes from "./FolderItem.module.css";

function FolderItem(props) {
  const {
    folder,
    folderName,
    lastModifyDate,
    parentID,
    id,
    path,
    setItemCheck,
    checkAll,
    createDate,
    role,
    getFoldersByParent,
  } = props;
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);
  const [lastModify, setLastModify] = useState("");

  const [showEditFolderModal, setShowEditFolderModal] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");

  const handleEditFolderShow = () => setShowEditFolderModal(true);
  const handleEditFolderClose = () => setShowEditFolderModal(false);

  const handleCheckBoxChange = () => {
    setIsChecked((prev) => !prev);
  };

  const updateFolder = (itemID, name) => {
    API.put(`/updateFolder`, {
      id: itemID,
      folder_name: name,
      original_name: folder.folder_name,
      path: folder.path,
      parent_id: folder.parent_id,
    })
      .then((response) => {
        alert(response.data.msg);
        getFoldersByParent();
        handleEditFolderClose();
      })
      .catch((e) => {
        alert(e.response.data.msg);
      });
  };
  const saveChanges = () => {
    updateFolder(folder.id, newFolderName);
  };

  useEffect(() => {
    if (isChecked) {
      setItemCheck((prev) => [...prev, folder.id]);
    } else {
      setItemCheck((prev) => prev.filter((item) => item !== folder.id));
    }
  }, [isChecked]);

  useEffect(() => {
    if (checkAll) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [checkAll]);

  function handleOnFolderClick(link, state) {
    navigate(link, { state });
  }

  return (
    <>
      {role === 1 && (
        <td>
          <div className={classes.checkbox_wrapper}>
            <input
              checked={isChecked}
              type="checkbox"
              onChange={handleCheckBoxChange}
              // contentEditable={!isChecked}
            />
          </div>
        </td>
      )}
      <td
        className={classes.folder_td_cell}
        onClick={() =>
          handleOnFolderClick(
            `/${folder.path.split("/").slice(1).join("/")}/${
              folder.folder_name
            }`,
            {
              parentPath: folder.path,
              currentFolderId: folder.id,
              currentName: folder.folder_name,
            }
          )
        }
      >
        <div className={classes.folder_box}>
          <img src={folderpng} alt="folder" className={classes.folder_icon} />
          <div className={classes.folder_link}>{folder.folder_name}</div>
        </div>
      </td>

      <td>
        {new Date(folder.last_modify_date.replace("Z", "")).toLocaleString(
          "en"
        )}
      </td>

      {role === 1 && (
        <td>
          <div className={classes.action_button_wrapper}>
            <CustomButton
              variant="outline-primary"
              onClick={handleEditFolderShow}
              className={classes.action_button}
            >
              Rename
            </CustomButton>
            <Confirmation
              content={`Please confirm ${
                folder.inactive === 1 ? "activate" : "deactivate"
              } this folder ?`}
              Children={
                <CustomButton
                  variant={
                    folder.inactive === 1 ? "outline-success" : "outline-danger"
                  }
                  className={classes.action_button}
                >
                  {folder.inactive === 0 ? "Deactivate" : "Activate"}
                </CustomButton>
              }
              onConfirm={() => {
                API.put("inactivateFolders", {
                  folder_id: folder.id,
                  folder_name: folder.folder_name,
                  folder_path: folder.path,
                  inactive: folder.inactive === 0 ? 1 : 0,
                })
                  .then((res) => {
                    getFoldersByParent();
                  })
                  .catch((e) => {
                    alert("Something goes wrong! Please try again.");
                  });
              }}
            />
          </div>
        </td>
      )}
      <Modal
        show={showEditFolderModal}
        onHide={handleEditFolderClose}
        backdrop="static"
      >
        <Modal.Header className="_g-modal-header_">
          <div className="_g-close-button-anchor_">
            <div className="_g-close-button_" onClick={handleEditFolderClose}>
              &#x2715;
            </div>
          </div>
          Edit Folder Name
        </Modal.Header>
        <Modal.Body className="_g-modal-body_">
          <input
            type="text"
            className="form-control"
            placeholder="New Folder Name"
            onChange={(e) => setNewFolderName(e.target.value)}
            maxLength={50}
          />
          <div className="_g-modal-button-wrapper_">
            <CustomButton
              variant="outline-secondary"
              onClick={handleEditFolderClose}
            >
              Close
            </CustomButton>
            <CustomButton variant="primary" onClick={saveChanges}>
              Save
            </CustomButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

FolderItem.propTypes = {
  id: PropTypes.number.isRequired,
  folderName: PropTypes.string.isRequired,
  lastModifyDate: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default FolderItem;
