// export const resultColors = [
//   "#0C4B8E",
//   "#4C56A1",
//   "#7A5FB1",
//   "#A568BC",
//   "#CF71C3",
//   "#F77BC5",
//   "#86E381",
//   "#00C4A1",
//   "#009FB7",
//   "#0076B2",
// ];

export const resultColors = {
  main: { text: "#cb4039", bar: "#ecc8c6", background: "#eee1e1" },
  secondary: { text: "black", bar: "#dddddd", background: "#F6F6F6" },
};
