import React from "react";
import { Modal, Form, Button, Figure } from "react-bootstrap";
import API from "../services/api";

import classes from "./CreateAccount.module.css";
import CustomButton from "./common/CustomButton";

const PASSWORD_REGEX = {
  short: /.{8,}/,
  numeric: /[0-9]/,
  lowercase: /[a-z]/,
  uppercase: /[A-Z]/,
  special: /[\!\@\#\$\%\^\&\*\(\)\-\_\=\+\`\~\[\{\]\}\\\|\;\:\'\"\,\<\.\>\/\?]/,
};

export default function CreateAccount({ setUsers }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [userState, setUserState] = React.useState({
    first_name: "",
    last_name: "",
    username: "",
    confirm_username: "",
  });

  const [firstPassword, setFirstPassword] = React.useState({
    value: "",
    visible: false,
  });
  const [secondPassword, setSecondPassword] = React.useState({
    value: "",
    visible: false,
  });

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    setUserState({
      first_name: "",
      last_name: "",
      username: "",
      confirm_username: "",
    });
    setFirstPassword({
      value: "",
      visible: false,
    });
    setSecondPassword({
      value: "",
      visible: false,
    });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    API.post("admin/register", {
      username: userState.username,
      password: firstPassword.value,
      firstName: userState.first_name,
      lastName: userState.last_name,
      confirmPassword: secondPassword.value,
    })
      .then((res) => {
        alert(res.data.msg);
        API.get("allusers")
          .then((response) => {
            setUsers(response.data.data.users);
          })
          .catch((e) => console.error(e));
        handleClose();
      })
      .catch((e) => {
        alert(e.response.data.msg);
      });
  };

  const formInvalid =
    firstPassword.value !== secondPassword.value ||
    !/^(?=.*\d*)(?=.*[!=@#$%^&*()_+,./:";'])(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(
      firstPassword.value
    ) ||
    userState.confirm_username !== userState.username;

  return (
    <>
      <CustomButton
        variant="outline-primary"
        onClick={handleOpen}
        className={classes.create_button}
      >
        Add New
      </CustomButton>
      <Modal
        show={isOpen}
        onHide={handleClose}
        dialogClassName={classes.create_modal_dialog}
        backdropClassName={classes.create_modal_backdrop}
        contentClassName={classes.create_modal_content}
        backdrop="static"
      >
        <Modal.Header className="_g-modal-header_">
          <div className="_g-close-button-anchor_">
            <div className="_g-close-button_" onClick={handleClose}>
              &#x2715;
            </div>
          </div>
          Create New User
        </Modal.Header>

        <Modal.Body className={`_g-modal-body_ ${classes.create_modal_body}`}>
          <Form
            onSubmit={(e) => onFormSubmit(e)}
            style={{
              display: "flex",
              gap: "15px",
              flexDirection: "column",
              margin: "15px 0",
            }}
          >
            <Form.Group>
              <Form.Label>
                First Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                required
                type="text"
                value={userState.first_name}
                onChange={(e) => {
                  setUserState((prev) => ({
                    ...prev,
                    first_name: e.target.value,
                  }));
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Last Name <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                required
                type="text"
                value={userState.last_name}
                onChange={(e) => {
                  setUserState((prev) => ({
                    ...prev,
                    last_name: e.target.value,
                  }));
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>
                Username / Email <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                required
                type="email"
                value={userState.username}
                onChange={(e) => {
                  setUserState((prev) => ({
                    ...prev,
                    username: e.target.value,
                  }));
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Confirm Username / Email <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                required
                type={"email"}
                value={userState.confirm_username}
                onChange={(e) => {
                  setUserState((prev) => ({
                    ...prev,
                    confirm_username: e.target.value,
                  }));
                }}
              />
            </Form.Group>
            {userState.username !== userState.confirm_username && (
              <div className={classes["error-feedback"]}>
                Both username must match.
              </div>
            )}
            <Form.Group style={{ position: "relative" }}>
              <Form.Label>
                New Passwor <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                required
                type={firstPassword.visible ? "text" : "password"}
                value={firstPassword.value}
                onChange={(e) => {
                  setFirstPassword((prev) => ({
                    ...prev,
                    value: e.target.value,
                  }));
                }}
              />
              <div
                className={
                  (firstPassword.visible === true
                    ? "icon-visible"
                    : "icon-invisible") +
                  " " +
                  classes.hide_icon
                }
                onClick={() =>
                  setFirstPassword((prev) => ({
                    ...prev,
                    visible: !prev.visible,
                  }))
                }
              />
            </Form.Group>
            <Form.Group style={{ position: "relative" }}>
              <Form.Label>
                Confirm Password <span style={{ color: "red" }}>*</span>
              </Form.Label>
              <Form.Control
                required
                type={secondPassword.visible ? "text" : "password"}
                value={secondPassword.value}
                onChange={(e) => {
                  setSecondPassword((prev) => ({
                    ...prev,
                    value: e.target.value,
                  }));
                }}
              />
              <div
                className={
                  (secondPassword.visible === true
                    ? "icon-visible"
                    : "icon-invisible") +
                  " " +
                  classes.hide_icon
                }
                onClick={() =>
                  setSecondPassword((prev) => ({
                    ...prev,
                    visible: !prev.visible,
                  }))
                }
              />
            </Form.Group>
            <div>
              {firstPassword.value !== secondPassword.value && (
                <div className={classes["error-feedback"]}>
                  Both passwords must match.
                </div>
              )}
              {firstPassword.value.length > 0 &&
                !PASSWORD_REGEX.short.test(firstPassword.value) && (
                  <div className={classes["error-feedback"]}>
                    Must be at least 8 characters.
                  </div>
                )}
              {firstPassword.value.length > 0 &&
                !PASSWORD_REGEX.special.test(firstPassword.value) && (
                  <div className={classes["error-feedback"]}>
                    Must contains at least 1 special characters.
                  </div>
                )}
              {firstPassword.value.length > 0 &&
                !PASSWORD_REGEX.lowercase.test(firstPassword.value) && (
                  <div className={classes["error-feedback"]}>
                    Must contains at least 1 lowercase letter.
                    <br />
                  </div>
                )}
              {firstPassword.value.length > 0 &&
                !PASSWORD_REGEX.uppercase.test(firstPassword.value) && (
                  <div className={classes["error-feedback"]}>
                    Must contains at least 1 uppercase letter.
                  </div>
                )}
            </div>
            <div className="_g-modal-button-wrapper_">
              <CustomButton variant="outline-secondary" onClick={handleClose}>
                Cancel
              </CustomButton>
              <CustomButton disabled={formInvalid} type="submit">
                Submit
              </CustomButton>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
